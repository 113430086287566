import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, Outlet } from 'react-router-dom'

import "./App.css";

import About from "./About";
import Home from "./Home";

import Register from "./auth/Register";
import AcctConfirm from "./auth/Confirm";
import ResetPassword from "./auth/Reset";
import Profile from "./auth/Profile";
import Login from "./auth/Login";
import Logout from "./auth/Logout";

import DriveList from "./DriveList";
import CreateDriver from "./CreateDriver";
import CreateLog from "./CreateLog";
import UpdateLog from "./UpdateLog";
import ViewDrive from "./ViewDrive";

const PrivateRoutes = () => {
  //let auth = {'token':true}
  return (
    localStorage.getItem('token') ?
      <Outlet/> : <Navigate to='/login'/>
  )
}

const App: React.FC = () => (
  <>
    <nav className="navbar navbar-expand">
      <a href="/" className="navbar-brand">
        <div className="font-weight-bold brand">
          <img src="/logo192.png" alt="logo" className="img-fluid" style={{ width: 40 }} />
        </div>
      </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          {localStorage.getItem('token') ? (
            <>
            <li className="nav-item"><a href="/create-log" className="nav-link">Add</a></li>
            <li className="nav-item"><a href="/profile" className="nav-link">Profile</a></li>
            <li className="nav-item"><a href="/logout" className="nav-link">Logout</a></li>
            </>

        ) : (
          <>
            <li className="nav-item"><a href="/login" className="nav-link">Login</a></li>
            <li className="nav-item"><a href="/register" className="nav-link">Register</a></li>
          </>
        )}
        </ul>
      </div>
    </nav>

    <div className="wrapper">
      <h2>LogBook</h2>
    </div>

    <BrowserRouter>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/register" element={<Register />} />
        <Route path="/confirm" element={<AcctConfirm />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route element={<PrivateRoutes/>}>
          <Route path="/" element={<Home />} />
          <Route path="/drive-list" element={<DriveList />} />
          <Route path="/create-driver" element={<CreateDriver />} />
          <Route path="/create-log" element={<CreateLog />} />
          <Route path="/update-log/:id" element={<UpdateLog />} />
          <Route path="/view-drive/:id" element={<ViewDrive />} />
          <Route path="/profile" element={<Profile/>} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>

    <div className="footer">
      <p>footer here ...</p>
    </div>

  </>
);
export default App;
