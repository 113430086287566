import React, { useState, useEffect } from 'react';
import './ViewDrive.css';

export default function DriverCard(
  driverid: any
) {

  const id = driverid.driverid;
  //console.log('id: ' + id);
  //console.log('driverid: ' + JSON.stringify(driverid));
  const [driver, setDriver] = useState<any[]>([])
    const fetchData = async () => {
      const response = await fetch(`https://pwa.magpiegrove.net/api/driver/read.php?id=${id}`)
      //const response = await fetch("http://localhost/reactjs/pwa-server-php/logbook/read.php")
      const data = await response.json()
      setDriver(data)
    }

    useEffect(() => {
      fetchData()
    }, [])

    let jsx = driver.map(
     (element, index) => (
       <div key={index} className="drive">
         <p>{element.driver} - {element.license}</p>
       </div>
      )
  );

  return (
    <>
      {jsx}
    </>
  );
};


