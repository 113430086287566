import React, { useEffect, useState } from "react"
import { useCallback } from 'react'
import './DriveList.css';

const DriveList: React.FC<{}> = () => {

  /*
  const [todos, setTodos] = useState<any[]>([])
  const fetchData = async () => {
    const response = await fetch("https://pwa.magpiegrove.net/api/logbook/read.php")
    //const response = await fetch("http://localhost/reactjs/pwa-server-php/logbook/read.php")
    const data = await response.json()
    setTodos(data)
  }

  useEffect(() => {
    fetchData()
  }, [])
  */
  const [drives, setDrives] = useState<any[]>([])

  const fetchData = useCallback(async () => {
    try {
      await fetch("https://pwa.magpiegrove.net/api/logbook/read.php", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          setDrives(data)
          //console.log("data: " + JSON.stringify(data));
        })
    } catch (error) {
      //console.log(error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  let jsx = drives.map(
     (element, index) => (
       <>
       <div key={index} className="drive-list">
         <div className="drive-date"><a href={"/view-drive/" + element.id}>{element.drive_date}</a></div>
         <div>{element.start_time}</div><div>{element.start_loc}</div><div>{element.end_time}</div><div>{element.end_loc}</div>
         <div>{element.road}</div><div>{element.weather}</div>
         <div>{element.traffic}</div><div>{element.driver}</div><div>{element.car}</div>
       </div>
       <hr/>
       </>
      )
  );

  return (
    <>
      <div className="container">
        <hr/>
        <div className="todo-result">
          {jsx}
        </div>
        <div className="spacer" />
      </div>
    </>
 )
};

export default DriveList;
