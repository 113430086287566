import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CreateDriver.css';

const CreateDriver: React.FC = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    driver: '',
    lic: '',
    sig: '',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    //let postData = new FormData();
    //postData.append('name', formData.name);
    //postData.append('lic', formData.lic);
    //postData.append('sig', formData.sig);
    console.log("form: " + JSON.stringify(formData));
    let postData = JSON.stringify(formData);
    console.log("post: " + postData);

    //fetch('http://localhost/reactjs/pwa-server-php/driver/create.php', {
    fetch('https://pwa.magpiegrove.net/api/driver/create.php', {
      method: 'POST',
      body: postData,
    })
      .then((response) => {
        navigate(`/`);
      })
      .then((data) => {
        // Handle the response data
        //console.log("data: " + JSON.stringify(data));
      })
      .catch((error) => {
        // Handle any errors
      });
  };

  return (
    <div className="form-div">
    <form onSubmit={handleSubmit}>
      <label>
        Name:<br/>
        <input type="text" name="driver" value={formData.driver} onChange={handleInputChange} />
      </label>
      <label>
        License:<br/>
        <input type="text" name="lic" value={formData.lic} onChange={handleInputChange} />
      </label>
      <label>
        Signature:<br/>
        <input type="text" name="sig" value={formData.sig} onChange={handleInputChange} />
      </label><br/>
      <div className="submit-div"> <button type="submit">Submit</button> </div>
    </form>
    </div>
  );
};

export default CreateDriver;
